<template>
  <div v-if="loading">Loading...</div>
  <div v-else-if="error">Error: {{ error.message }}</div>
  <div v-else-if="headingMetaData" class="st-view-wrapper">
    <div class="st-view-header-wrapper">
      <div class="st-view-header-content">
        <router-link class="button breadcrumbs-item" to="/policies">
          Policies
        </router-link>
        <span class="breadcrumbs-item">&lt;</span>
        <router-link
          class="button breadcrumbs-item"
          :to="{ path: '/policy/' + currentPolicyName }"
        >
          {{ currentPolicyName }}
        </router-link>
        <span v-if="currentExecutionID" class="breadcrumbs-item">&lt;</span>
        <router-link
          v-if="currentExecutionID"
          class="button breadcrumbs-item"
          :to="{
            path: '/policy/' + currentPolicyName + '/' + currentExecutionID,
          }"
        >
          {{ currentExecutionID }}
        </router-link>
        <span class="breadcrumbs-item">&lt;</span>
        <span class="text-muted breadcrumbs-item"> {{ resourceKey }} </span>

        <div class="st-metadata-wrapper">
          <div>
            <div class="text-muted st-header-title-tag">
              RESOURCE CORE METADATA
            </div>

            <h5 class="st-header-title" data-testid="resource-key-title">
              {{ resourceKey }}
            </h5>
          </div>
          <div class="container st-metadata-body-wrapper">
            <div class="row">
              <div
                v-for="(data, index) in headingMetaData"
                :key="index"
                class="col-md-3 st-meta-data-item-wrapper"
              >
                <div class="st-meta-data-title-tag">{{ data.headerTitle }}</div>
                <div class="st-meta-data-title">{{ data.value }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { useQuery, useResult } from "@vue/apollo-composable";
import resourceQuery from "../graphql/resource.query.gql";
import { resourcesColumnsMapping } from "@/utils/resourcesColumnsMapping.js";
import { getFormatDateTime } from "@/utils/datetime.js";
export default {
  name: "ResourceDetail",
  setup() {
    const route = useRoute();
    const currentPolicyName = route.params.policyName;
    const currentExecutionID = route.params.executionID;
    const resourceKey = route.params.resourceKey;

    const { result, loading, error } = useQuery(resourceQuery, {
      key: resourceKey,
    });

    const headingMetaData = useResult(result, [], (data) => {
      let resourceType = data.resource.resourceType;
      if (!resourcesColumnsMapping[resourceType]) {
        resourceType = "default";
      }
      const resourceData = JSON.parse(data.resource.data);
      const dataArray = resourcesColumnsMapping[resourceType].columns.map(
        (columnData) => {
          let valueData;
          if (columnData.attribute.includes(".")) {
            const attArray = columnData.attribute.split(".");
            valueData = resourceData[attArray[0]][attArray[1]] || "--";
          } else {
            valueData = resourceData[columnData.attribute] || "--";
          }

          if (columnData.attribute === "webAclArn") {
            valueData = resourceData[columnData.attribute] ? "Yes" : "No";
          }
          if (columnData.type === "time") {
            valueData = resourceData[columnData.attribute]
              ? getFormatDateTime(resourceData[columnData.attribute])
              : "--";
          }
          return {
            headerTitle: columnData.name,
            value: valueData,
          };
        }
      );
      return dataArray;
    });

    return {
      loading,
      error,
      route,
      currentPolicyName,
      currentExecutionID,
      headingMetaData,
      resourceKey,
    };
  },
};
</script>

<style lang="scss" scoped>
.st-header-title {
  margin-top: 8px;
}

.st-metadata-body-wrapper {
  margin-left: 0;
}
</style>
