export const resourcesColumnsMapping = {
  ami: {
    columns: [
      {
        name: "Name",
        attribute: "Name",
        type: "string",
      },
      {
        name: "Platform",
        attribute: "Platform",
        type: "string",
      },
      {
        name: "Image ID",
        attribute: "ImageId",
        type: "string",
      },
      {
        name: "Creation Date",
        attribute: "CreationDate",
        type: "time",
      },
      {
        name: "State",
        attribute: "State",
        type: "string",
      },
    ],
  },
  "acm-certificate": {
    columns: [
      {
        name: "Certificate Arn",
        attribute: "CertificateArn",
        type: "string",
      },
      {
        name: "Subject Alternative Names",
        attribute: "SubjectAlternativeNames",
        type: "string",
      },
      {
        name: "Domain Name",
        attribute: "DomainName",
        type: "string",
      },
      {
        name: "Created At",
        attribute: "CreatedAt",
        type: "time",
      },
      {
        name: "Status",
        attribute: "Status",
        type: "string",
      },
      {
        name: "Not After",
        attribute: "NotAfter",
        type: "time",
      },
      {
        name: "In Use By",
        attribute: "InUseBy",
        type: "string",
      },
    ],
  },
  "app-elb": {
    columns: [
      {
        name: "Load Balancer Name",
        attribute: "LoadBalancerName",
        type: "string",
      },
      {
        name: "DNS Name",
        attribute: "DNSName",
        type: "string",
      },
      {
        name: "VPC Id",
        attribute: "VPCId",
        type: "string",
      },
      {
        name: "Type",
        attribute: "Type",
        type: "string",
      },
      {
        name: "State",
        attribute: "State",
        type: "string",
      },
      {
        name: "Created Time",
        attribute: "CreatedTime",
        type: "time",
      },
    ],
  },
  asg: {
    columns: [
      {
        name: "Auto Scaling Group Name",
        attribute: "AutoScalingGroupName",
        type: "string",
      },
      {
        name: "Launch Configuration Name",
        attribute: "LaunchConfigurationName",
        type: "string",
      },
      {
        name: "Launch Template",
        attribute: "LaunchTemplate",
        type: "string",
      },
      {
        name: "Min Size",
        attribute: "MinSize",
        type: "string",
      },
      {
        name: "Max Size",
        attribute: "MaxSize",
        type: "string",
      },
    ],
  },
  "cache-cluster": {
    columns: [
      {
        name: "Cache Cluster Id",
        attribute: "CacheClusterId",
        type: "string",
      },
      {
        name: "Cache Cluster Create Time",
        attribute: "CacheClusterCreateTime",
        type: "time",
      },
      {
        name: "Cache Cluster Status",
        attribute: "CacheClusterStatus",
        type: "string",
      },
      {
        name: "Configuration Endpoint",
        attribute: "ConfigurationEndpoint",
        type: "string",
      },
      {
        name: "Engine",
        attribute: "Engine",
        type: "string",
      },
      {
        name: "Engine Version",
        attribute: "EngineVersion",
        type: "string",
      },
      {
        name: "Num Cache Nodes",
        attribute: "NumCacheNodes",
        type: "string",
      },
    ],
  },
  "cache-snapshot": {
    columns: [
      {
        name: "Snapshot Name",
        attribute: "SnapshotName",
        type: "string",
      },
      {
        name: "Cache Cluster Id",
        attribute: "CacheClusterId",
        type: "string",
      },
      {
        name: "Snapshot Source",
        attribute: "SnapshotSource",
        type: "string",
      },
    ],
  },
  cfn: {
    columns: [
      {
        name: "Stack Name",
        attribute: "StackName",
        type: "string",
      },
      {
        name: "Creation Time",
        attribute: "CreationTime",
        type: "time",
      },
      {
        name: "Last Updated Time",
        attribute: "LastUpdatedTime",
        type: "time",
      },
      {
        name: "Stack Status",
        attribute: "StackStatus",
        type: "string",
      },
      {
        name: "Stack Status Reason",
        attribute: "StackStatusReason",
        type: "string",
      },
    ],
  },
  cloudsearch: {
    columns: [
      {
        name: "Domain Name",
        attribute: "DomainName",
        type: "string",
      },
      {
        name: "Created",
        attribute: "Created",
        type: "string",
      },
      {
        name: "Deleted",
        attribute: "Deleted",
        type: "string",
      },
      {
        name: "Search Instance Type",
        attribute: "SearchInstanceType",
        type: "string",
      },
      {
        name: "Search Instance Count",
        attribute: "SearchInstanceCount",
        type: "string",
      },
      {
        name: "Requires Index Documents",
        attribute: "RequiresIndexDocuments",
        type: "string",
      },
      {
        name: "Processing",
        attribute: "Processing",
        type: "string",
      },
    ],
  },
  cloudtrail: {
    columns: [
      {
        name: "Name",
        attribute: "Name",
        type: "string",
      },
      {
        name: "S3 Bucket Name",
        attribute: "S3BucketName",
        type: "string",
      },
      {
        name: "Include Global Service Events",
        attribute: "IncludeGlobalServiceEvents",
        type: "string",
      },
      {
        name: "Is Multi Region Trail",
        attribute: "IsMultiRegionTrail",
        type: "string",
      },
      {
        name: "Log File Validation Enabled",
        attribute: "LogFileValidationEnabled",
        type: "string",
      },
      {
        name: "Kms Key Id",
        attribute: "KmsKeyId",
        type: "string",
      },
      {
        name: "Home Region",
        attribute: "HomeRegion",
        type: "string",
      },
      {
        name: "Is Organization Trail",
        attribute: "IsOrganizationTrail",
        type: "string",
      },
    ],
  },
  codebuild: {
    columns: [
      {
        name: "Name",
        attribute: "name",
        type: "string",
      },

      {
        name: "Encryption Key",
        attribute: "encryptionKey",
        type: "string",
      },
      {
        name: "Created",
        attribute: "created",
        type: "time",
      },
      {
        name: "lastModified",
        attribute: "lastModified",
        type: "time",
      },
      {
        name: "privilegedMode",
        attribute: "privilegedMode",
        type: "string",
      },
      {
        name: "vpcId",
        attribute: "vpcConfig.vpcId",
        type: "string",
      },
    ],
  },
  distribution: {
    columns: [
      {
        name: "Domain Name",
        attribute: "DomainName",
        type: "string",
      },
      {
        name: "Last Modified Time",
        attribute: "LastModifiedTime",
        type: "time",
      },
      {
        name: "Id",
        attribute: "Id",
        type: "string",
      },
      {
        name: "Viewer Protocol Policy",
        attribute: "ViewerProtocolPolicy",
        type: "string",
      },
      {
        name: "Price Class",
        attribute: "PriceClass",
        type: "string",
      },
      {
        name: "Enabled",
        attribute: "Enabled",
        type: "string",
      },
    ],
  },
  "dynamodb-table": {
    columns: [
      {
        name: "Table Name",
        attribute: "TableName",
        type: "string",
      },
      {
        name: "Creation Date Time",
        attribute: "CreationDateTime",
        type: "time",
      },
      {
        name: "SSE Description",
        attribute: "SSEDescription",
        type: "string",
      },
      {
        name: "Table Status",
        attribute: "TableStatus",
        type: "string",
      },
      {
        name: "Table Size Bytes",
        attribute: "TableSizeBytes",
        type: "string",
      },
      {
        name: "Billing Mode Summary",
        attribute: "BillingModeSummary",
        type: "string",
      },
    ],
  },
  ebs: {
    columns: [
      {
        name: "Volume Id",
        attribute: "VolumeId",
        type: "string",
      },
      {
        name: "Create Time",
        attribute: "CreateTime",
        type: "time",
      },
      {
        name: "State",
        attribute: "State",
        type: "string",
      },
    ],
  },
  "ebs-snapshot": {
    columns: [
      {
        name: "Snapshot Id",
        attribute: "SnapshotId",
        type: "string",
      },
      {
        name: "Start Time",
        attribute: "StartTime",
        type: "time",
      },
    ],
  },
  ec2: {
    columns: [
      {
        name: "Instance ID",
        attribute: "InstanceId",
        type: "string",
      },
      {
        name: "Instance Type",
        attribute: "InstanceType",
        type: "string",
      },
      {
        name: "Image ID",
        attribute: "ImageId",
        type: "string",
      },
      {
        name: "Private IP Address",
        attribute: "PrivateIpAddress",
        type: "string",
      },
      {
        name: "Launch Time",
        attribute: "LaunchTime",
        type: "time",
      },
      {
        name: "Key Name",
        attribute: "KeyName",
        type: "string",
      },
      {
        name: "VPC ID",
        attribute: "VPCId",
        type: "string",
      },
      {
        name: "Subnet ID",
        attribute: "SubnetId",
        type: "string",
      },
    ],
  },
  //cant check this
  "ecs-service": {
    columns: [
      {
        name: "Service Name",
        attribute: "serviceName",
        type: "string",
      },
      {
        name: "Cluster Arn",
        attribute: "clusterArn",
        type: "string",
      },
      {
        name: "Launch Type",
        attribute: "launchType",
        type: "string",
      },
      {
        name: "Task Definition",
        attribute: "taskDefinition",
        type: "string",
      },
      {
        name: "Create Time",
        attribute: "CreateTime",
        type: "string",
      },
      {
        name: "Created At",
        attribute: "createdAt",
        type: "string",
      },
      {
        name: "Enable ECS Managed Tags",
        attribute: "enableECSManagedTags",
        type: "string",
      },
      {
        name: "Propagate Tags",
        attribute: "propagateTags",
        type: "string",
      },
    ],
  },
  "ecs-task": {
    columns: [
      {
        name: "Task Arn",
        attribute: "taskArn",
        type: "string",
      },
      {
        name: "Task Definition Arn",
        attribute: "taskDefinitionArn",
        type: "string",
      },
      {
        name: "Cluster Arn",
        attribute: "clusterArn",
        type: "string",
      },
      {
        name: "Connectivity",
        attribute: "connectivity",
        type: "string",
      },
      {
        name: "Created At",
        attribute: "createdAt",
        type: "string",
      },
      {
        name: "Group",
        attribute: "group",
        type: "string",
      },
      {
        name: "Launch Type",
        attribute: "launchType",
        type: "string",
      },
      {
        name: "Version",
        attribute: "version",
        type: "string",
      },
      {
        name: "Containers",
        attribute: "containers",
        type: "string",
      },
    ],
  },
  efs: {
    columns: [
      {
        name: "Creation Token",
        attribute: "CreationToken",
        type: "string",
      },
      {
        name: "Creation Time",
        attribute: "CreationTime",
        type: "string",
      },
      {
        name: "File System Id",
        attribute: "FileSystemId",
        type: "string",
      },
      {
        name: "Owner Id",
        attribute: "OwnerId",
        type: "string",
      },
      {
        name: "Performance Mode",
        attribute: "PerformanceMode",
        type: "string",
      },
      {
        name: "Encrypted",
        attribute: "Encrypted",
        type: "string",
      },
    ],
  },
  eks: {
    columns: [
      {
        name: "Status",
        attribute: "status",
        type: "string",
      },
      {
        name: "Endpoint",
        attribute: "endpoint",
        type: "string",
      },
      {
        name: "Name",
        attribute: "name",
        type: "string",
      },
      {
        name: "Role Arn",
        attribute: "roleArn",
        type: "string",
      },
      {
        name: "Version",
        attribute: "version",
        type: "string",
      },
      {
        name: "Created At",
        attribute: "createdAt",
        type: "string",
      },
      {
        name: "Platform Version",
        attribute: "platformVersion",
        type: "string",
      },
    ],
  },
  "elasticbeanstalk-environment": {
    columns: [
      {
        name: "Environment Name",
        attribute: "EnvironmentName",
        type: "string",
      },
      {
        name: "Environment Id",
        attribute: "EnvironmentId",
        type: "string",
      },
      {
        name: "Application Name",
        attribute: "ApplicationName",
        type: "string",
      },
      {
        name: "Date Created",
        attribute: "DateCreated",
        type: "time",
      },
      {
        name: "Status",
        attribute: "Status",
        type: "string",
      },
      {
        name: "Health",
        attribute: "Health",
        type: "string",
      },
      {
        name: "Health Status",
        attribute: "HealthStatus",
        type: "string",
      },
    ],
  },
  elasticsearch: {
    columns: [
      {
        name: "Domain Name",
        attribute: "DomainName",
        type: "string",
      },
      {
        name: "Endpoint",
        attribute: "Endpoint",
        type: "string",
      },
    ],
  },
  elb: {
    columns: [
      {
        name: "Load Balancer Name",
        attribute: "LoadBalancerName",
        type: "string",
      },
      {
        name: "Availability Zones",
        attribute: "AvailabilityZones",
        type: "string",
      },
      {
        name: "DNSName",
        attribute: "DNSName",
        type: "string",
      },
      {
        name: "VPCId",
        attribute: "VPCId",
        type: "string",
      },
      {
        name: "Subnets",
        attribute: "Subnets",
        type: "string",
      },
      {
        name: "CreatedTime",
        attribute: "CreatedTime",
        type: "time",
      },
    ],
  },
  emr: {
    columns: [
      {
        name: "Id",
        attribute: "Id",
        type: "string",
      },
      {
        name: "Name",
        attribute: "Name",
        type: "string",
      },
      {
        name: "Normalized Instance Hours",
        attribute: "NormalizedInstanceHours",
        type: "string",
      },
      {
        name: "Status State",
        attribute: "Status.State",
        type: "string",
      },
    ],
  },
  "glue-job": {
    columns: [
      {
        name: "Name",
        attribute: "Name",
        type: "string",
      },
      {
        name: "Created On",
        attribute: "CreatedOn",
        type: "time",
      },
      {
        name: "Command",
        attribute: "Command",
        type: "string",
      },
      {
        name: "Timeout",
        attribute: "Timeout",
        type: "string",
      },
      {
        name: "Allocated Capacity",
        attribute: "AllocatedCapacity",
        type: "string",
      },
      {
        name: "Glue Version",
        attribute: "GlueVersion",
        type: "string",
      },
    ],
  },
  "iam-user": {
    columns: [
      {
        name: "User Name",
        attribute: "UserName",
        type: "string",
      },
      {
        name: "User Id",
        attribute: "UserId",
        type: "string",
      },
      {
        name: "Create Date",
        attribute: "CreateDate",
        type: "time",
      },
      {
        name: "Password Last Used",
        attribute: "PasswordLastUsed",
        type: "time",
      },
    ],
  },
  kinesis: {
    columns: [
      {
        name: "Stream Name",
        attribute: "StreamName",
        type: "string",
      },
      {
        name: "Stream Arn",
        attribute: "StreamArn",
        type: "time",
      },
      {
        name: "Stream Status",
        attribute: "StreamStatus",
        type: "string",
      },
      {
        name: "Stream Creation Timestamp",
        attribute: "StreamCreationTimestamp",
        type: "time",
      },
      {
        name: "Encryption Type",
        attribute: "EncryptionType",
        type: "string",
      },
      {
        name: "Key Id",
        attribute: "KeyId",
        type: "string",
      },
    ],
  },
  "kms-key": {
    columns: [
      {
        name: "Key Id",
        attribute: "KeyId",
        type: "string",
      },
      {
        name: "Creation Date",
        attribute: "CreationDate",
        type: "time",
      },
      {
        name: "Enabled",
        attribute: "Enabled",
        type: "string",
      },
      {
        name: "Key Usage",
        attribute: "KeyUsage",
        type: "string",
      },
      {
        name: "Key State",
        attribute: "KeyState",
        type: "string",
      },
      {
        name: "Valid To",
        attribute: "ValidTo",
        type: "time",
      },
      {
        name: "Description",
        attribute: "Description",
        type: "string",
      },
      {
        name: "Key Manager",
        attribute: "KeyManager",
        type: "string",
      },
    ],
  },
  lambda: {
    columns: [
      {
        name: "Function Name",
        attribute: "FunctionName",
        type: "string",
      },
      {
        name: "Runtime",
        attribute: "Runtime",
        type: "string",
      },
      {
        name: "Description",
        attribute: "Description",
        type: "string",
      },
      {
        name: "Version",
        attribute: "Version",
        type: "string",
      },
      {
        name: "Last Modified",
        attribute: "LastModified",
        type: "string",
      },
      {
        name: "VpcConfig.VpcId",
        attribute: "VpcConfig.VpcId",
        type: "string",
      },
      {
        name: "VpcConfig.SubnetIds",
        attribute: "VpcConfig.SubnetIds",
        type: "string",
      },
      {
        name: "KMSKeyArn",
        attribute: "KMSKeyArn",
        type: "string",
      },
      {
        name: "State",
        attribute: "State",
        type: "string",
      },
    ],
  },
  "launch-config": {
    columns: [
      {
        name: "Launch Configuration Name",
        attribute: "LaunchConfigurationName",
        type: "string",
      },
      {
        name: "Image Id",
        attribute: "ImageId",
        type: "string",
      },
      {
        name: "Key Name",
        attribute: "KeyName",
        type: "string",
      },
      {
        name: "Instance Type",
        attribute: "InstanceType",
        type: "string",
      },
      {
        name: "Created Time",
        attribute: "CreatedTime",
        type: "time",
      },
      {
        name: "Associate Public Ip Address",
        attribute: "AssociatePublicIpAddress",
        type: "string",
      },
    ],
  },
  "log-group": {
    columns: [
      {
        name: "Log Group Name",
        attribute: "logGroupName",
        type: "string",
      },
      {
        name: "Creation Time",
        attribute: "creationTime",
        type: "string",
      },
      {
        name: "Stored Bytes",
        attribute: "storedBytes",
        type: "string",
      },
      {
        name: "Metric Filter Count",
        attribute: "metricFilterCount",
        type: "string",
      },
      {
        name: "kmsKeyId",
        attribute: "kmsKeyId",
        type: "string",
      },
    ],
  },
  "message-broker": {
    columns: [
      {
        name: "Broker Id",
        attribute: "BrokerId",
        type: "string",
      },
      {
        name: "Broker Name",
        attribute: "BrokerName",
        type: "string",
      },
      {
        name: "Broker State",
        attribute: "BrokerState",
        type: "string",
      },
      {
        name: "Created",
        attribute: "Created",
        type: "time",
      },
      {
        name: "Publicly Accessible",
        attribute: "PubliclyAccessible",
        type: "string",
      },
      {
        name: "Logs",
        attribute: "Logs",
        type: "string",
      },
      {
        name: "Deployment Mode",
        attribute: "DeploymentMode",
        type: "string",
      },
      {
        name: "Encryption Options",
        attribute: "EncryptionOptions",
        type: "string",
      },
      {
        name: "Engine Type",
        attribute: "EngineType",
        type: "string",
      },
    ],
  },
  rds: {
    columns: [
      {
        name: "DB Instance Identifier",
        attribute: "DBInstanceIdentifier",
        type: "string",
      },
      {
        name: "Publicly Accessible",
        attribute: "PubliclyAccessible",
        type: "string",
      },
      {
        name: "KmsKeyId",
        attribute: "KmsKeyId",
        type: "string",
      },
      {
        name: "Engine",
        attribute: "Engine",
        type: "string",
      },
      {
        name: "InstanceCreateTime",
        attribute: "InstanceCreateTime",
        type: "time",
      },
      {
        name: "BackupRetentionPeriod",
        attribute: "BackupRetentionPeriod",
        type: "string",
      },
      {
        name: "MultiAZ",
        attribute: "MultiAZ",
        type: "string",
      },
      {
        name: "Vpc ID",
        attribute: "DBSubnetGroup.VpcId",
        type: "string",
      },
      {
        name: "DBSubnetGroup.Subnets.SubnetIdentifier",
        attribute: "DBSubnetGroup.Subnets.SubnetIdentifier",
        type: "string",
      },
      {
        name: "DeletionProtection",
        attribute: "DeletionProtection",
        type: "string",
      },
    ],
  },
  "rds-cluster": {
    columns: [
      {
        name: "Cluster Create Time",
        attribute: "ClusterCreateTime",
        type: "time",
      },
      {
        name: "Database Name",
        attribute: "DatabaseName",
        type: "string",
      },
      {
        name: "DB Cluster Identifier",
        attribute: "DBClusterIdentifier",
        type: "string",
      },
      {
        name: "Allocated Storage",
        attribute: "AllocatedStorage",
        type: "string",
      },
      {
        name: "Backup Retention Period",
        attribute: "BackupRetentionPeriod",
        type: "string",
      },
      {
        name: "Multi AZ",
        attribute: "MultiAZ",
        type: "string",
      },
      {
        name: "Engine",
        attribute: "Engine",
        type: "string",
      },
      {
        name: "Engine Version",
        attribute: "EngineVersion",
        type: "string",
      },
      {
        name: "Storage Encrypted",
        attribute: "StorageEncrypted",
        type: "string",
      },
      {
        name: "Kms Key Id",
        attribute: "KmsKeyId",
        type: "string",
      },
      {
        name: "Enabled Cloudwatch Logs Exports",
        attribute: "EnabledCloudwatchLogsExports",
        type: "string",
      },
    ],
  },
  "rds-snapshot": {
    columns: [
      {
        name: "DB Snapshot Identifier",
        attribute: "DBSnapshotIdentifier",
        type: "string",
      },
      {
        name: "Snapshot Create Time",
        attribute: "SnapshotCreateTime",
        type: "time",
      },
      {
        name: "DB Instance Identifier",
        attribute: "DBInstanceIdentifier",
        type: "string",
      },
      {
        name: "Snapshot Type",
        attribute: "SnapshotType",
        type: "string",
      },
    ],
  },
  redshift: {
    columns: [
      {
        name: "Cluster Identifier",
        attribute: "ClusterIdentifier",
        type: "string",
      },
      {
        name: "Cluster Create Time",
        attribute: "ClusterCreateTime",
        type: "time",
      },
      {
        name: "Node Type",
        attribute: "NodeType",
        type: "string",
      },
      {
        name: "Cluster Status",
        attribute: "ClusterStatus",
        type: "string",
      },
      {
        name: "Vpc ID",
        attribute: "VpcId",
        type: "string",
      },
      {
        name: "Kms Key ID",
        attribute: "KmsKeyId",
        type: "string",
      },
      //Not sure if we have this key for redshift
      {
        name: "Node Count",
        attribute: "NodeCount",
        type: "string",
      },
      {
        name: "DB Name",
        attribute: "DBName",
        type: "string",
      },
      {
        name: "Publicly Accessible",
        attribute: "PubliclyAccessible",
        type: "string",
      },
      {
        name: "Encrypted",
        attribute: "Encrypted",
        type: "string",
      },
    ],
  },
  "redshift-snapshot": {
    columns: [
      {
        name: "Snapshot Identifier",
        attribute: "SnapshotIdentifier",
        type: "string",
      },
      {
        name: "DB Name",
        attribute: "DBName",
        type: "string",
      },
    ],
  },
  "rest-api": {
    columns: [
      {
        name: "id",
        attribute: "id",
        type: "string",
      },
      {
        name: "name",
        attribute: "name",
        type: "string",
      },
      {
        name: "Created Date",
        attribute: "createdDate",
        type: "time",
      },
      {
        name: "Endpoint Configuration Types",
        attribute: "endpointConfiguration.types",
        type: "array",
      },
    ],
  },
  "rest-stage": {
    columns: [
      {
        name: "Deployment Id",
        attribute: "deploymentId",
        type: "string",
      },
      {
        name: "Stage Name",
        attribute: "stageName",
        type: "string",
      },
      {
        name: "Cache Cluster Enabled",
        attribute: "cacheClusterEnabled",
        type: "string",
      },
      {
        name: "Cache Cluster Status",
        attribute: "cacheClusterStatus",
        type: "string",
      },
      {
        name: "Method Settings",
        attribute: "methodSettings",
        type: "string",
      },
      {
        name: "Tracing Enabled",
        attribute: "tracingEnabled",
        type: "string",
      },
      {
        name: "Created Date",
        attribute: "createdDate",
        type: "time",
      },
      {
        name: "Last Updated Date",
        attribute: "lastUpdatedDate",
        type: "time",
      },
      {
        name: "WAF Protected",
        attribute: "webAclArn",
        type: "string",
      },
    ],
  },
  "sagemaker-notebook": {
    columns: [
      {
        name: "Notebook Instance Name",
        attribute: "NotebookInstanceName",
        type: "string",
      },
      {
        name: "Instance Type",
        attribute: "InstanceType",
        type: "string",
      },
      {
        name: "Creation Time",
        attribute: "CreationTime",
        type: "time",
      },
      {
        name: "Kms Key Id",
        attribute: "KmsKeyId",
        type: "string",
      },
      {
        name: "Direct Internet Access",
        attribute: "DirectInternetAccess",
        type: "string",
      },
    ],
  },
  "sagemaker-model": {
    columns: [
      {
        name: "Model Name",
        attribute: "ModelName",
        type: "string",
      },
      {
        name: "Creation Time",
        attribute: "CreationTime",
        type: "time",
      },
    ],
  },
  s3: {
    columns: [
      {
        name: "Name",
        attribute: "Name",
        type: "string",
      },
      {
        name: "Creation Date",
        attribute: "CreationDate",
        type: "time",
      },
      {
        name: "Location Constraint",
        attribute: "Location.LocationConstraint",
        type: "string",
      },
      {
        name: "Versioning Status",
        attribute: "Versioning.Status",
        type: "string",
      },
      {
        name: "Website",
        attribute: "Website",
        type: "string",
      },
      {
        name: "Target Bucket",
        attribute: "Logging.TargetBucket",
        type: "string",
      },
      {
        name: "Replication",
        attribute: "Replication",
        type: "string",
      },
    ],
  },
  "security-group": {
    columns: [
      {
        name: "Group Name",
        attribute: "GroupName",
        type: "string",
      },
      {
        name: "Description",
        attribute: "Description",
        type: "string",
      },
      {
        name: "Group Id",
        attribute: "GroupId",
        type: "string",
      },
      {
        name: "Vpc Id",
        attribute: "VpcId",
        type: "string",
      },
    ],
  },
  simpledb: {
    columns: [
      {
        name: "Domain Name",
        attribute: "DomainName",
        type: "string",
      },
    ],
  },
  sns: {
    columns: [
      {
        name: "Topic Arn",
        attribute: "TopicArn",
        type: "string",
      },
      {
        name: "Display Name",
        attribute: "DisplayName",
        type: "string",
      },
      {
        name: "Kms Master Key Id",
        attribute: "KmsMasterKeyId",
        type: "string",
      },
    ],
  },
  sqs: {
    columns: [
      {
        name: "Queue Arn",
        attribute: "QueueArn",
        type: "string",
      },
      {
        name: "Created Timestamp",
        attribute: "CreatedTimestamp",
        type: "time",
      },
      {
        name: "KmsMasterKeyId",
        attribute: "KmsMasterKeyId",
      },
      {
        name: "VisibilityTimeout",
        attribute: "VisibilityTimeout",
      },
      {
        name: "FifoQueue",
        attribute: "FifoQueue",
      },
    ],
  },
  default: {
    columns: [
      {
        name: "Resource ID",
        attribute: "id",
      },
      {
        name: "Other",
        attribute: "other",
      },
    ],
  },
};
